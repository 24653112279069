
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ppd-loreal-primary: mat.define-palette(mat.$indigo-palette);
$ppd-loreal-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ppd-loreal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ppd-loreal-theme: mat.define-light-theme((
  color: (
    primary: $ppd-loreal-primary,
    accent: $ppd-loreal-accent,
    warn: $ppd-loreal-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ppd-loreal-theme);

/* You can add global styles to this file, and also import other style files */

@import '~swiper/swiper';
@import "~swiper/components/pagination/pagination";

@import '~lightgallery/scss/lightgallery';
@import '~lightgallery/scss/lg-zoom';
@import '~lightgallery/scss/lg-video';

@import '~@ctrl/ngx-emoji-mart/picker';

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@charset "UTF-8";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  outline: none;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
}

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

* {
  box-sizing: border-box; }

*:before,
*:after {
  box-sizing: border-box;
}

@font-face {
  font-family: CenturyGothic;
  src: url(./assets/fonts/CenturyGothic.ttf) format("truetype");
}
@font-face {
  font-family: CenturyGothicBold;
  src: url(./assets/fonts/Century-Gothic-Bold.ttf) format("truetype");
}

html, body {
  max-width: 100%;
  height: 100%;
  position: relative;
  font-size: 16px;
  line-height: 1.6;
  font-family: "CenturyGothic", Helvetica, sans-serif;
  color: #000000;
  background-color: #000000;
  overflow: hidden;
}

a {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #000000;
}
a:hover{
  text-decoration: none;
}


.html_text {
  margin: 0 0 50px 0;
  @media screen and (max-width: 425px) {
    font-size: 14px;
  }
  h1, h2 {
    padding: 0 0 30px 0;
  }
  h3, h4, h5, h6 {
    padding: 0 0 20px 0;
  }
  p {
    padding: 0 0 20px 0;
  }
  ol {
    list-style: decimal;
    list-style-position: inside;
    padding: 0 0 20px 0;
  }
  ul {
    list-style: disc;
    list-style-position: inside;
    padding: 0 0 20px 0;
  }
  table {
    td {
      padding: 3px;
      border: 1px solid #000000;
    }
  }
}

.chat_text {
  a {
    word-break: break-all;
  }
  @media screen and (max-width: 320px) {
    font-size: 13px;
  }
}

/* Диалоговые окна */
.mat-dialog-container {
  padding: 0 !important;
}

.custom_dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  padding: 25px;
  ul {
    list-style: disc;
    li {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 700px) {
    width: auto;
    height: auto;
  }
  &.attestation_test_result {
    align-items: flex-start;
    font-family: CenturyGothicBold, sans-serif;
    font-size: 20px;
  }
  &.user_change {
    justify-content: flex-start;
    align-items: flex-start;
    width: 500px;
    padding: 15px;
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
    @media screen and (max-width: 500px) {
      width: 280px;
    }
    @media screen and (max-width: 380px) {
      width: 240px;
    }
  }
  &.management_dialog_form {
    display: block;
    width: 900px;
    height: 900px;
    background: #e7e6e6;
    padding: 0;
    @media screen and (max-width: 1600px) {
      height: 650px;
    }
    @media screen and (max-width: 1200px) {
      width: 850px;
    }
    @media screen and (max-width: 900px) {
      width: 620px;
    }
    @media screen and (max-width: 500px) {
      width: 340px;
    }
    @media screen and (max-width: 400px) {
      width: 300px;
    }
    @media screen and (max-width: 325px) {
      width: 250px;
    }
  }
  &.team {
    width: 1100px;
    padding: 0;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (max-width: 1400px) {
      width: 930px;
    }
    @media screen and (max-width: 1200px) {
      width: 730px;
      justify-content: center;
      align-items: center;
    }
    @media screen and (max-width: 900px) {
      width: 560px;
    }
    @media screen and (max-width: 700px) {
      width: 450px;
    }
    @media screen and (max-width: 500px) {
      width: 320px;
    }
    @media screen and (max-width: 320px) {
      width: 250px;
    }
  }
  &.feedback {
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 600px;
    overflow: auto;
  }
  &.feedback-rating {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-height: 700px;
    gap: 20px;
  }
  &.document-viewed {
    display: block;
    width: 700px;
    max-height: 600px;
    overflow: auto;
    background: #e7e7e7;
    .mat-checkbox-inner-container {
      width: 60px!important;
      height: 60px!important;
    }
    .mat-checkbox-frame {
      border: 3px solid #000000;
    }
    @media screen and (max-width: 900px) {
      width: auto;
    }
  }
  &.rating-info {
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;
  }
}

.mat-dialog-title {
  font-family: "CenturyGothic", sans-serif !important;
  font-weight: bold;
}

.dialog-title {
  font-family: "CenturyGothic", sans-serif;
  font-size: 25px;
  text-align: center;
  @media screen and (max-width: 425px) {
    font-size: 20px;
  }
}

.dialog-text {
  font-family: "CenturyGothic", sans-serif;
  font-size: 20px;
  @media screen and (max-width: 320px) {
    font-size: 17px;
  }
}

.dialog-content {
  .input-container {
    margin: 0 0 25px 0;
    width: 100%;
    justify-self: auto;
    display: inline-block;
    vertical-align: top;
    font-family: "CenturyGothic", sans-serif;
    @media only screen and (max-width: 520px) {
      margin: 0 0 15px 0;
    }
    input, textarea {
      color: #000000;
      outline: none;
      width: 100%;
      height: 50px;
      font-size: 16px;
      line-height: 50px;
      padding: 0 24px;
      border: 2px solid #d4d4d4;
      &.invalid-input {
        border-color:#FF0000;
      }
      &.edit-input {
        border-color: green;
        color: green;
      }
      @media only screen and (max-width: 520px) {
        height: 40px;
        font-size: 14px;
        line-height: 40px;
        padding: 0 10px;
      }
    }
    .error {
      padding: 5px 0 0 0;
      color: red;
      @media screen and (max-width: 425px) {
        font-size: 17px;
      }
    }
  }
}

.dialog-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  &.user_change_actions {
    justify-content: center;
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.dialog_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 45px;
  background: #000000;
  font-family: "CenturyGothic", sans-serif;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
  @media screen and (max-width: 425px) {
    font-size: 17px;
  }
  &.user_change_button {
    margin: 0 auto;
    border: 1px solid #000000;
  }
  &.user_change_button_test {
    margin: 0 auto;
    border: 1px solid #000000;
    border-radius: 10px;
    font-size: 18px;
  }
}

/* Диалоговые окна (конец) */

.outer {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100%;
  background: #e7e6e6;
  padding: 0;
  margin: 0 auto;
  &.auth {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: url("/assets/images/bg.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    @media (min-width: 320px) and (max-width: 1024px) {
      background-image: url("/assets/images/bgm.png");
    }
  }
}

.workspace {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding: 80px 0 0;
  margin: 0 auto;
  @media screen and (max-width: 1440px) {
    padding: 80px 0 0 0;
  }
  @media screen and (max-width: 1024px) {
    padding: 80px 90px 0;
  }
  @media screen and (max-width: 768px) {
    padding: 80px 60px 0;
    height: 70vh;
  }
  @media screen and (max-width: 425px) {
    padding: 80px 30px 0;
  }
  &.mobile_menu {
    padding: 80px 0 0 0;
  }
  &.main {
    padding: 80px 0 0 0;
    margin: 0 auto;
    @media screen and (max-width: 1440px) {
      padding: 80px 0 0 0;
    }
    @media screen and (max-width: 1024px) {
      padding: 80px 0 0 0;
      height: 100vh;
    }
    @media screen and (max-width: 500px) {
      height: 90vh;
    }
  }
  &.management {
    padding: 0 127px 0;
    height: 80vh;
    @media screen and (max-width: 1440px) {
      padding: 0 125px 0;
    }
    @media screen and (max-width: 1024px) {
      padding: 0 90px 0;
    }
    @media screen and (max-width: 768px) {
      padding: 0 60px 0;
    }
    @media screen and (max-width: 425px) {
      padding: 0 30px 0;
    }
  }
  &.doc {
    height: 90vh;
    padding: 50px 185px 0;
    @media screen and (max-width: 1440px) {
      padding: 25px 150px 0;
    }
    @media screen and (max-width: 768px) {
      padding: 25px 50px 0;
      height: 75vh;
    }
    @media screen and (max-width: 500px) {
      padding: 25px 10px 0;
    }
  }
  &.test {
    height: 90vh;
    @media screen and (max-width: 768px) {
      height: 75vh;
    }
    @media screen and (max-width: 500px) {
      height: 80vh;
    }
  }
  &.personal {
    @media screen and (max-width: 1024px) {
      padding: 80px 90px 0;
    }
    @media screen and (max-width: 768px) {
      padding: 80px 60px 0;
    }
    @media screen and (max-width: 425px) {
      padding: 80px 10px 0;
    }
  }
  &.rating {
    @media screen and (max-width: 1024px) {
      padding: 80px 90px 0;
    }
    @media screen and (max-width: 768px) {
      padding: 80px 0 0;
    }
  }
  &.chat {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: fit-content;
    padding: 0;
    margin: 0 auto 45px auto;
    border-radius: 15px;
    background: url(/assets/images/chat_bg.png) no-repeat;
    background-size: cover;
  }
}

.back_button {
  display: flex;
  align-items: center;
  margin: 0 0 55px 0;
  cursor: pointer;
  .back_icon {
    width: 60px;
    height: 60px;
    margin: 0 20px 0 0;
    background: url('/assets/images/icons/back.png');
    background-size: cover;
    @media screen and (max-width: 425px) {
      width: 30px;
      height: 30px;
    }
  }
  .back_title {
    font-family: "CenturyGothicBold", sans-serif;
    font-size: 27px;
    @media screen and (max-width: 425px) {
      font-size: 20px;
    }
  }
  &.back_button_management {
    margin: 0;
  }
}

/* Авторизация */

app-auth {
  .mat-checkbox-inner-container {
    width:20px;
    height:20px;
    position:relative;
    top:-2px;
    background-color: #c6a45a;
    border: 2px solid #000000;
    border-radius: 5px;
    @media (min-width: 320px) and (max-width: 424px) {
      width: 15px;
      height: 15px;
    }
    @media (min-width: 425px) and (max-width: 1024px) {
      width: 17px;
      height: 17px;
      top:-4px;
    }

    .mat-checkbox-frame {
      border:none;
    }
    .mat-checkbox-background {
      top:2px;
      right:2px;
      bottom:2px;
      left:2px;
      border-radius: 3px;
    }
    .mat-checkbox-background svg {
      display:none;
    }
  }
}

.mat-ripple-element  {
  background: rgba(116, 116, 116, 0.4) !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #000000 !important;
}
/* Авторизация (конец) */

// Убираем контент при открытии меню на мобильных девайсах
.open_mobile_menu {
  display: none !important;
}

app-side-menu {
  top: 0;
  position: sticky;
  @media screen and (max-width: 1024px) {
    position: relative;
  }
}

/* Настройки свайпера */

.custom_swiper {
  width: 100%;
  height: 268px;
  .swiper-wrapper {
    .swiper-slide {
      &:nth-last-child(1) {
        width: inherit !important;
      }
    }
  }
  .slide_image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: 1440px) {
      background-size: contain;
    }
    @media screen and (max-width: 900px) {
      background-size: cover;
    }
  }
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    font-size: 14px;
    background-color: #ffffff;
  }
}


/* Настройка свайпера (конец) */

/* Кастомный скролл-бар */
.custom_scroll {
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, .05);
    border-radius: calc(16px / 4);
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .15);
    border-radius: calc(16px / 4);
    border: 0 solid rgba(0, 0, 0, .05);
  }
}
/* Кастомный скролл-бар (конец) */

/* Тесты */
.options-group {
  &.for_matching {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media screen and (max-width: 500px) {
      width: 100%;
    }
    .options-DropList {
      margin: 0;
      .text {
        @media screen and (max-width: 1200px) {
          font-size: 12px;
        }
        @media screen and (max-width: 320px) {
          font-size: 11px;
        }
      }
      @media screen and (max-width: 500px) {
        padding: 0;
        justify-content: center;
      }
    }
    .options-button {
      @media screen and (max-width: 500px) {
        min-height: 50px;
      }
    }
  }
  .cdk-drag-placeholder {
    opacity: 0;
  }
  .options-DropList {
    border: 2px solid transparent;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, .15);
    cursor: pointer;
    &.half_left {
      border: 2px solid rgba(0, 0, 0, .15);
      background-color: transparent;
    }
    &.default_cursor {
      cursor: default;
    }
    .number {
      border-radius: 100%;
      border: 1px solid #000;
      width: 25px;
      height: 25px;
      margin: 0 20px 0 0;
      line-height: 23px;
      display: block;
      text-align: center;
      color: #000000;
    }
    .text {
      color: #000000;
      &.disabled {
        color: rgba($color: #000000, $alpha: .5);
        cursor: default;
      }
    }
  }

  .correct {
    background: lightgreen;
    cursor: default;
  }

  .incorrect {
    background: lightcoral;
    cursor: default;
  }

  .options-button {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
    color: #505050;
    font-size: 14px;
    position: relative;

    .mat-radio-outer-circle {
      border-color: rgba(0, 0, 0, .54) !important;
      height: 25px;
      width: 25px;
    }

    .mat-radio-inner-circle {
      height: 25px;
      width: 25px;
      background-color: #000000 !important;
    }
    .mat-radio-label, .mat-checkbox-layout{
      white-space: normal;
      display: block;
      padding: 5px 0;
      .mat-radio-container, .mat-checkbox-inner-container {
        position: absolute;
        top: 50%;
        height: 25px;
        width: 25px;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
      }
      .mat-radio-label-content, .mat-checkbox-label {
        padding-left: 50px;
        display: block;
        line-height: 1.5;
      }
    }
  }
  &.for_admin {
    .options-button {
      width: 30px;
      margin: 0;
      .mat-radio-label, .mat-checkbox-layout {
        padding: 0;
      }
    }
  }
}

.options-textarea {
  border-radius: 3px;
  background-color: #A9A9A9;
  padding: 30px;
  textarea {
    width: 100%;
    height: 100px;
    padding: 20px;
    resize: none;
  }
}
/* Тесты (конец) */

/* Кастомный слайдер для тестов */
.custom-slider {
  margin-top: 10px;
  .mat-slider-wrapper {
    .mat-slider-track-wrapper {
      .mat-slider-track-fill {
        background-color: red;
      }
    }
    .mat-slider-thumb-container {
      .mat-slider-thumb-label {
        background-color: red;
      }
      .mat-slider-thumb {
        background-color: red;
      }
    }
  }
}
/* Кастомный слайдер для тестов (конец) */

/* Кастомный таб для просмотра ответов */
.custom_attempts_tab {
  font-family: "CenturyGothic", sans-serif;
  .mat-tab-header {
    .mat-tab-label-container {
      .mat-tab-list {
        .mat-tab-labels {
          .mat-tab-label {
            .mat-tab-label-content {
              font-family: "CenturyGothic", sans-serif;
            }
          }
        }
      }
    }
  }
  .mat-ink-bar {
    background-color: #000000 !important;
  }
}
/* Кастомный таб для просмотра ответов (конец) */


/* Кастомные стили для управления */
/* Эдитор */
.ck-editor__editable_inline {
  min-height: 200px !important;
  max-height: 200px !important;
}
/* Эдитор (конец) */

.mat-pseudo-checkbox-checked {
  background: #000000;
}

.custom_select {
  &.for_management_filters {
    width: 240px;
    height: 45px;
    background: #ffffff;
    border-radius: 10px;
    padding: 0 10px;
    .mat-select-trigger {
      height: 100%;
    }
    .mat-select-value {
      vertical-align: middle;
    }
  }
  &.for_management {
    height: 45px;
    background: #ffffff;
    border-radius: 10px;
    padding: 0 10px;
    font-family: CenturyGothic, sans-serif;
    .mat-select-trigger {
      height: 100%;
    }
    .mat-select-value {
      vertical-align: middle;
    }
  }
  &.for_update_user {
    width: 80%;
    height: 35px;
    background: #ffffff;
    border-radius: 10px;
    font-family: CenturyGothic, sans-serif;
    .mat-select-trigger {
      height: 100%;
    }
    .mat-select-value {
      vertical-align: middle;
    }
  }
  &.for_infographics_filters {
    width: 100%;
    height: 45px;
    background: #ffffff;
    border-radius: 10px;
    padding: 0 10px;
    margin-top: 20px;
    .mat-select-trigger {
      height: 100%;
    }
    .mat-select-value {
      vertical-align: middle;
    }
  }
  &.for-feedback {
    width: 100%;
    height: 40px;
    background: #ffffff;
    border-radius: 10px;
    font-family: CenturyGothic, sans-serif;
    padding: 10px;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: .15);
  }
  &.for-feedback-management {
    max-width: 300px;
    height: 40px;
    background: #ffffff;
    border-radius: 10px;
    font-family: CenturyGothic, sans-serif;
    padding: 10px;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: .15);
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }
}

/* Кастомные стили для управления (конец) */

/*Скролл в таблице инфографики */
.table_scroll {
  height: 500px !important;
}

/* Стиль для перетаскивания файла в форму сообщения в чате */
.drop_zone {
  border: none;
  border-radius: 0;
  height: 50px;
  background: rgba($color:#dce1e6, $alpha: .5);
}

.drop_zone_content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 0 0 15px 15px;
}
/* Стиль для перетаскивания файла в форму сообщения в чате (конец) */

ng-scrollbar {
  --scrollbar-size: 8px;
  --scrollbar-thumb-color: #28df93;
  --scrollbar-thumb-hover-color: rgb(231, 46, 46);
  --scrollbar-border-radius: 4px;
  height: 400px !important;
  @media screen and (max-width: 500px) {
    height: 300px !important;
  }
}

youtube-player {
  iframe {
    width: 500px;
    height: 250px;
    @media screen and (max-width: 500px) {
      width: 250px;
      height: 125px;
    }
    @media screen and (max-width: 320px) {
      width: 200px;
      height: 130px;
    }
  }
}

.emoji-mart {
  width: 300px !important;
}

.cdk-drag-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .15);
  cursor: pointer;
  &.default_cursor {
    cursor: default;
  }
  .number {
    border-radius: 100%;
    border: 1px solid #000;
    width: 25px;
    height: 25px;
    margin: 0 20px 0 0;
    line-height: 23px;
    display: block;
    text-align: center;
    color: #000000;
  }
  .text {
    color: #000000;
    @media screen and (max-width: 1200px) {
      font-size: 12px;
    }
    @media screen and (max-width: 320px) {
      font-size: 11px;
    }
    &.disabled {
      color: rgba($color: #000000, $alpha: .5);
      cursor: default;
    }
  }
}

.custom_checkbox {
  .mat-checkbox-layout {
    .mat-checkbox-inner-container {
      width: 20px;
      height: 20px;
    }
  }
}


.team_scrollbar {
  width: 100%;
  height: 830px !important;
  @media screen and (max-width: 1600px) {
    height: 630px !important;
  }
}


.mat-slide-toggle {
  .mat-slide-toggle-bar {
    width: 90px;
    height: 20px;
    background-color: rgb(149 149 149 / 38%) !important;
    @media screen and (max-width: 500px) {
      width: 200px;
    }
  }
  .mat-slide-toggle-thumb {
    height: 25px;
    width: 25px;
    background-color: rgb(198,164,90) !important;
  }
  .mat-slide-toggle-thumb-container {
    transform: translate3d(0px, 0, 0);
  }
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(65px, 0, 0) !important;
  @media screen and (max-width: 500px) {
    transform: translate3d(175px, 0, 0) !important;
  }
}
